import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[decimalInput]',
  standalone: true,
})
export class DecimalInputDirective implements OnInit {
  public regexStr = '^[0-9.,-]*$';

  public constructor(private ngControl: NgControl) {}

  public ngOnInit() {
    const valueOnChange = (this.ngControl.valueAccessor as any).onChange;
    (this.ngControl.valueAccessor as any).onChange = (value) => valueOnChange(this.validateInput(value));
  }

  @HostListener('keypress', ['$event'])
  public onKeypress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('ngModelChange', ['$event'])
  public ngModelChange(value: string) {
    this.ngControl.valueAccessor.writeValue(this.validateInput(value));
  }

  public validateInput(event: string) {
    return event.toString().replace(/[^0-9 .,-]/g, '');
  }
}
