<ng-container *ngIf="!addOn && !icon">
  <input
    [id]="inputId"
    class="decimal-input"
    [ngClass]="{ 'form-modal__input-short': !fullWidth, 'form-modal__input': fullWidth }"
    type="text"
    [ngModel]="displayValue"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onChangeInput($event)"
    (keydown.ArrowUp)="incrementInteger($event)"
    (keydown.ArrowDown)="decrementInteger($event)"
    [disabled]="disabled"
    (blur)="onBlur()"
    decimalInput
  />
</ng-container>
<ng-container *ngIf="icon">
  <div [ngClass]="{ 'input-group-short': !fullWidth, 'input-group': fullWidth }">
    <div class="input-group-addon"><icon class="si" [svgIcon]="icon"></icon></div>
    <input
      [id]="inputId"
      class="input-group-short decimal-input"
      type="text"
      [ngModel]="displayValue"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onChangeInput($event)"
      (keydown.ArrowUp)="incrementInteger($event)"
      (keydown.ArrowDown)="decrementInteger($event)"
      [disabled]="disabled"
      (blur)="onBlur()"
      decimalInput
    />
  </div>
</ng-container>
<ng-container *ngIf="addOn">
  <div [ngClass]="{ 'input-group-short': !fullWidth, 'input-group': fullWidth }">
    <input
      *ngIf="!icon"
      [id]="inputId"
      class="decimal-input"
      [ngClass]="{ 'form-modal__input-short': !fullWidth, 'form-modal__input': fullWidth }"
      type="text"
      [ngModel]="displayValue"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onChangeInput($event)"
      (keydown.ArrowUp)="incrementInteger($event)"
      (keydown.ArrowDown)="decrementInteger($event)"
      [disabled]="disabled"
      (blur)="onBlur()"
      decimalInput
    />
    <div class="input-group-addon" [ngClass]="{ clickable: clickable }">
      <icon class="si" svgIcon="{{ addOn }}"></icon>
    </div>
  </div>
</ng-container>
